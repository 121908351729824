// Generated by Framer (33d5032)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, ResolveLinks, useComponentViewport, useLocaleInfo, useRouter, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import MenuButtonCopy from "./Q_3j6Pc3S";
import MenuButton from "./YSHkPf5h5";
const MenuButtonFonts = getFonts(MenuButton);
const MenuButtonCopyFonts = getFonts(MenuButtonCopy);

const cycleOrder = ["U4eSLswZD"];

const serializationHash = "framer-EI99Y"

const variantClassNames = {U4eSLswZD: "framer-v-1gfmiql"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "U4eSLswZD", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const router = useRouter()

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1gfmiql", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"U4eSLswZD"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><ResolveLinks links={[{href: {webPageId: "augiA20Il"}, implicitPathVariables: undefined}]}>{(resolvedLinks) => (
<ComponentViewportProvider ><motion.div className={"framer-18t93j5-container"} layoutDependency={layoutDependency} layoutId={"nwRWQ0Dvi-container"}><MenuButton cF6n1cby9={"See Plans"} height={"100%"} id={"nwRWQ0Dvi"} kNRuUXBud layoutId={"nwRWQ0Dvi"} nCCctwwUG={"ArrowUpRight"} style={{height: "100%"}} UA0jqQuxp={false} uNbulzTsz={resolvedLinks[0]} variant={"vCWuyC20i"} width={"100%"}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks><ComponentViewportProvider ><motion.div className={"framer-1cjgc56-container"} layoutDependency={layoutDependency} layoutId={"jTgJrMb3O-container"}><MenuButtonCopy cF6n1cby9={"About Us"} height={"100%"} id={"jTgJrMb3O"} kNRuUXBud={false} layoutId={"jTgJrMb3O"} nCCctwwUG={"House"} style={{height: "100%"}} UA0jqQuxp={false} variant={"YH64T8tSX"} width={"100%"}/></motion.div></ComponentViewportProvider><ComponentViewportProvider ><motion.div className={"framer-aklxge-container"} layoutDependency={layoutDependency} layoutId={"z8M9a32VI-container"}><MenuButtonCopy cF6n1cby9={"iOS App Store"} height={"100%"} id={"z8M9a32VI"} kNRuUXBud={false} layoutId={"z8M9a32VI"} nCCctwwUG={"InstagramLogo"} style={{height: "100%"}} UA0jqQuxp={false} variant={"YH64T8tSX"} width={"100%"}/></motion.div></ComponentViewportProvider><ComponentViewportProvider ><motion.div className={"framer-28di7y-container"} layoutDependency={layoutDependency} layoutId={"rNeLC3LxM-container"}><MenuButtonCopy cF6n1cby9={"Andoid Play Store"} height={"100%"} id={"rNeLC3LxM"} kNRuUXBud={false} layoutId={"rNeLC3LxM"} nCCctwwUG={"LinkedinLogo"} style={{height: "100%"}} UA0jqQuxp={false} variant={"PC37QKCsg"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-EI99Y.framer-7ml8sf, .framer-EI99Y .framer-7ml8sf { display: block; }", ".framer-EI99Y.framer-1gfmiql { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: auto; justify-content: center; overflow: hidden; padding: 16px; position: relative; width: 1200px; }", ".framer-EI99Y .framer-18t93j5-container, .framer-EI99Y .framer-1cjgc56-container, .framer-EI99Y .framer-aklxge-container, .framer-EI99Y .framer-28di7y-container { flex: none; height: 44px; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-EI99Y.framer-1gfmiql { gap: 0px; } .framer-EI99Y.framer-1gfmiql > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-EI99Y.framer-1gfmiql > :first-child { margin-left: 0px; } .framer-EI99Y.framer-1gfmiql > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 76
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerejWGj7U_k: React.ComponentType<Props> = withCSS(Component, css, "framer-EI99Y") as typeof Component;
export default FramerejWGj7U_k;

FramerejWGj7U_k.displayName = "Fixed Floating Navbar";

FramerejWGj7U_k.defaultProps = {height: 76, width: 1200};

addFonts(FramerejWGj7U_k, [{explicitInter: true, fonts: []}, ...MenuButtonFonts, ...MenuButtonCopyFonts], {supportsExplicitInterCodegen: true})